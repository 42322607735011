<template>
  <div
    class="position-fixed top-0 bottom-0 end-0 start-0 d-flex justify-content-center align-items-center"
    v-if="showSpinner"
  >
    <div
      class="h-100 w-100 bg-white position-absolute"
      style="opacity: 0.8"
    ></div>
    <div class="spinner-border" role="status" style="width: 5rem; height: 5rem">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Spinner",
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    ...mapGetters({
      showSpinner: "showSpinner",
    }),
  },
};
</script>
